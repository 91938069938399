interface Array<T> {
    /**
     * Creates an object-map with the given mapper
     * @example
     * [
     *   {id:1,color:'red'},
     *   {id:2,color:'blue'},
     *   {id:3,color:'red'}
     * ].groupBy(x => x.color) =>
     *
     * {
     *   red: [
     *     {id:1,color:'red'},
     *     {id:3,color:'red'}
     *   ],
     *   blue: [
     *     {id:2,color:'blue'}
     *   ]
     * }
     */
    groupBy(mapper: (elem: T) => string): { [key: string]: T[] };
}

Array.prototype.groupBy = function <T>(mapper: (elem: T) => string): { [key: string]: T[] } {
    return this.reduce((acc, cur) => {
        const key = mapper(cur);
        if (typeof key !== 'string') {
            throw new Error(`The mapper did not get a string, for object: ${cur}`);
        }
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(cur);
        return acc;
    }, {});
};
